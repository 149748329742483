import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import iepLogo from "./iepLogo.png";
//import logofinal from "./logofinal.png"
//import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import IconButton from '@material-ui/core/IconButton';
//import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
//import PlayArrowIcon from '@material-ui/icons/PlayArrow';
//import SkipNextIcon from '@material-ui/icons/SkipNext';
//import Link from '@material-ui/core/Link';
//import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
/*
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-scroll";
*/

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff"
  },
  link: {
    margin: theme.spacing(0.5, 3),
    textTransform: "none",
    textDecoration: "none",
    color: "#505256",
    "&:hover": {
      fontWeight: "normal",
      textDecoration: "none",
      color: "#000"
    }
  },
  buttonLeft: {
    marginLeft: "10px",
    borderRadius: "8px",
    textTransform: "none",
    backgroundColor: "#F1F2F4",
    color: "#505256",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#E1E4E8",
      boxShadow: "none",
      color: "#000"
    }
  },
  media: {
    height: "3rem",
    width: "3rem"
  },

  
  
  
  
  drawer: {
    width: "100%",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "100%",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },


  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: "5rem",
    height: "5rem"
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  
  
}));

const WebBar = props => {
  const classes = useStyles();
  //const theme = useTheme();
  //const { scrollToVerse } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  /*
  const [openMenu, setOpenMenu] = useState(false);

  const handleDrawerOpen = () => {
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpenMenu(false);
  };
  */

  return (
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar>
        <Grid item container xs={12} style={{ marginTop: "1em" }} alignItems="center">
          <Grid item xs={1} xl={2} />
          {matches ? (
            <Grid item container xs={12} justify="center">
              <Grid item container xs={12} alignItems="center" justify="center">
                <CardMedia
                  className={classes.cover}
                  image={iepLogo}
                  title="Live from space album cover"
                />
                <div className={classes.details}>
                  <CardContent className={classes.content}>

                    <Typography compon color="inherit" style={{  fontSize: "16px", fontWeight: "bold", marginTop: "0.5rem" }} align="center">
                      I.E.P Melipilla
                    </Typography>
                  </CardContent>
                </div>
              
              {/*}
            </Card>
*/}
            </Grid>
            {/*}
            <Grid item contianer xs={12}>
              <CardMedia
                className={classes.media}
                image={iepLogo}
                //image="/static/images/cards/contemplative-reptile.jpg"
                //image="https://source.unsplash.com/random"
                title="Contemplative Reptile"
              />
              <Typography compon color="inherit" style={{  fontSize: "16px", fontStyle: "italic" }} align="center">
                Congregados
              </Typography>
            </Grid>

            <Grid item container xs={12}>
              <Typography compon color="inherit" style={{  fontSize: "16px", fontStyle: "italic" }} align="center">
                Congregados
              </Typography>
            </Grid>

            <Grid item container xs={12} style={{ marginTop: "-0.4rem" }}>

              <Typography color="inherit" style={{  fontSize: "16px", fontStyle: "italic" }}>
                desde casa
              </Typography>
            </Grid>
*/}
            
            

{/*}



            {matches ? (
              <Grid item container xs={12} alignItems="center">
                <Grid item container xs={8} justify="flex-start">
                  <Typography color="inherit" style={{ fontWeight: "bold", fontSize: "20px" }}>
                    I.E.P Melipilla
                  </Typography>
                </Grid>
                <Grid item container xs={4} justify="flex-end">
                  <IconButton
                    color="inherit"
                    aria-label="o"
                    onClick={handleDrawerOpen} 
                    //edge="start"
                    //className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>


                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="right"
                  open={openMenu}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <div className={classes.drawerHeader}>

                    <Grid item container xs={12}>
                      <Grid item xs={1} />
                      <Grid item container xs={10} justify="space-between" alignItems="center">
                        <Grid item container xs={12} alignItems="center">
                          <Grid item xs={10}>
                            <Typography align="center">
                              I.E.P Melipilla
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={handleDrawerClose}>
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </div>

                  <Divider />

                  <div className={classes.drawerHeader}>

                    <Grid item container xs={12}>
                      <Grid item xs={1} />
                      <Grid item container xs={10} justify="space-between" alignItems="center">
                        <Grid item container xs={12} alignItems="center" justify="center">
                          <p>item </p>
                        </Grid>

                      </Grid>
                    </Grid>
                  </div>
                  <Divider />
                  <List>
                    {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                      <ListItem style={{ justifyContent: "center" }} button key={text} alignItems="center" justify="center">
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <List>
                    {['All mail', 'Trash', 'Spam'].map((text, index) => (
                      <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </Grid>
            ) : (

              <Grid item container xs={12}>
                <Grid item container xs={5} justify="flex-start">
                  <Typography variant="h6" color="inherit" style={{ fontWeight: "bold" }}>
                    I.E.P Melipilla
                  </Typography>
                </Grid>
                <Grid item container xs={7} justify="flex-end">
                  <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                    Features
                  </Link>
                  <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                    Enterprise
                  </Link>
                  <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                    Support
                  </Link>
                  <Link
    activeClass="active"
    to="verse"
    spy={true}
    smooth={true}
    offset={-70}
    duration={500}
>verso</Link>
                  <Button onClick={scrollToVerse} variant="contained" className={classes.buttonLeft} >Documentation</Button>
                </Grid>
              </Grid>
            )}

            */}

          </Grid>
          ) : (
<Grid item container xs={10} xl={8} justify="flex-start">
            <Grid item container xs={12} alignItems="center">
              {/*}
              <Card className={classes.root}>
  */}
                <CardMedia
                  className={classes.cover}
                  image={iepLogo}
                  title="Live from space album cover"
                />
                <div className={classes.details}>
                  <CardContent className={classes.content}>

                    <Typography compon color="inherit" style={{  fontSize: "16px", fontWeight: "bold", marginTop: "0.5rem" }} align="center">
                      I.E.P Melipilla
                    </Typography>
                  </CardContent>
                </div>
              
              {/*}
            </Card>
*/}
            </Grid>
            {/*}
            <Grid item contianer xs={12}>
              <CardMedia
                className={classes.media}
                image={iepLogo}
                //image="/static/images/cards/contemplative-reptile.jpg"
                //image="https://source.unsplash.com/random"
                title="Contemplative Reptile"
              />
              <Typography compon color="inherit" style={{  fontSize: "16px", fontStyle: "italic" }} align="center">
                Congregados
              </Typography>
            </Grid>

            <Grid item container xs={12}>
              <Typography compon color="inherit" style={{  fontSize: "16px", fontStyle: "italic" }} align="center">
                Congregados
              </Typography>
            </Grid>

            <Grid item container xs={12} style={{ marginTop: "-0.4rem" }}>

              <Typography color="inherit" style={{  fontSize: "16px", fontStyle: "italic" }}>
                desde casa
              </Typography>
            </Grid>
*/}
            
            

{/*}



            {matches ? (
              <Grid item container xs={12} alignItems="center">
                <Grid item container xs={8} justify="flex-start">
                  <Typography color="inherit" style={{ fontWeight: "bold", fontSize: "20px" }}>
                    I.E.P Melipilla
                  </Typography>
                </Grid>
                <Grid item container xs={4} justify="flex-end">
                  <IconButton
                    color="inherit"
                    aria-label="o"
                    onClick={handleDrawerOpen} 
                    //edge="start"
                    //className={clsx(classes.menuButton, open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>


                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="right"
                  open={openMenu}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <div className={classes.drawerHeader}>

                    <Grid item container xs={12}>
                      <Grid item xs={1} />
                      <Grid item container xs={10} justify="space-between" alignItems="center">
                        <Grid item container xs={12} alignItems="center">
                          <Grid item xs={10}>
                            <Typography align="center">
                              I.E.P Melipilla
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton onClick={handleDrawerClose}>
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </div>

                  <Divider />

                  <div className={classes.drawerHeader}>

                    <Grid item container xs={12}>
                      <Grid item xs={1} />
                      <Grid item container xs={10} justify="space-between" alignItems="center">
                        <Grid item container xs={12} alignItems="center" justify="center">
                          <p>item </p>
                        </Grid>

                      </Grid>
                    </Grid>
                  </div>
                  <Divider />
                  <List>
                    {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                      <ListItem style={{ justifyContent: "center" }} button key={text} alignItems="center" justify="center">
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <List>
                    {['All mail', 'Trash', 'Spam'].map((text, index) => (
                      <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </Grid>
            ) : (

              <Grid item container xs={12}>
                <Grid item container xs={5} justify="flex-start">
                  <Typography variant="h6" color="inherit" style={{ fontWeight: "bold" }}>
                    I.E.P Melipilla
                  </Typography>
                </Grid>
                <Grid item container xs={7} justify="flex-end">
                  <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                    Features
                  </Link>
                  <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                    Enterprise
                  </Link>
                  <Link variant="button" color="textPrimary" href="#" className={classes.link}>
                    Support
                  </Link>
                  <Link
    activeClass="active"
    to="verse"
    spy={true}
    smooth={true}
    offset={-70}
    duration={500}
>verso</Link>
                  <Button onClick={scrollToVerse} variant="contained" className={classes.buttonLeft} >Documentation</Button>
                </Grid>
              </Grid>
            )}

            */}

          </Grid>
          )}
          
          <Grid item xs={1} xl={2} /> 
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default WebBar;

WebBar.propTypes = {
  scrollToVerse: PropTypes.func
};

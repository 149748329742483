import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
//import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
//import Link from "@material-ui/core/Link";
//import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import MenuBookIcon from "@material-ui/icons/MenuBook";
//import { MenuBook } from "@material-ui/icons";

const useStyles = makeStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "12px",
    border: "1px solid #F7F8F9",
    boxShadow: "0 0 1px 0 rgba(8, 11, 14, 0.06), 0 6px 6px -1px rgba(8, 11, 14, 0.1)",
    padding: "1rem"
  },
  cardContent: {
    flexGrow: 1,
  },
  title: {
    marginTop: "1.5rem",
    lineHeight: "32px",
    fontSize: "22px",
    fontWeight: 500
  },
  subtitle: {
    marginTop: "1rem",
    lineHeight: "30px",
    fontSize: "16px",
    color: "#505256"
  },
  subtitleItem: {
    marginTop: "1rem",
    lineHeight: "30px",
    fontSize: "16px",
    color: "#0284FE"
  },
  link: {
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: "5px",
    color: "#0284FE",
    fontWeight: 500,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#026DD6"
    }
  }
});

const CardsInformation = () => {
  const classes = useStyles();

  return (
    <Grid container style={{ marginTop: "3rem" }}>
      <Grid item container xs={12}>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid item xs={12}>
                    <MenuBookIcon style={{ color: "#ffcd38", width: "3rem", height: "3rem", marginLeft: "-5px" }} />
                  </Grid>
                  <Typography className={classes.title}>
                    Semana del 4 al 10
                  </Typography>

                  <Typography component="span" className={classes.subtitleItem}>
                    <Typography className={classes.subtitle}>
                      Viernes 8 (20:00 hrs.)
                    </Typography>
                    Reunión de juventud
                    <Typography component="span" className={classes.subtitle}>, a través de la plataforma</Typography>
                    &nbsp;Zoom
                  </Typography>

                  <Typography component="span" className={classes.subtitleItem}>
                    <Typography className={classes.subtitle}  style={{ marginTop: "3rem" }} >
                      Sábado 9 (19:30 hrs.)
                    </Typography>
                    Media vigilia de voluntarios
                    <Typography component="span" className={classes.subtitle}>, a través de las plataformas de</Typography>
                    &nbsp;Youtube <Typography component="span" className={classes.subtitle}> y</Typography>
                    &nbsp;Zoom
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid item xs={12}>
                    <MenuBookIcon style={{ color: "#ff9800", width: "3rem", height: "3rem", marginLeft: "-5px" }} />
                  </Grid>
                  <Typography className={classes.title}>
                    Semana del 11 al 17
                  </Typography>

                  <Typography component="span" className={classes.subtitleItem}>
                    <Typography className={classes.subtitle}>
                      Viernes 15 (20:00 hrs.)
                    </Typography>
                    Reunión de dorcas
                    <Typography component="span" className={classes.subtitle}>, a través de la plataforma</Typography>
                    &nbsp;Zoom
                  </Typography>

                  <Typography component="span" className={classes.subtitleItem}>
                    <Typography className={classes.subtitle} style={{ marginTop: "3rem" }} >
                      Sábado 16 (20:00 hrs.)
                    </Typography>
                    Reunión general
                    <Typography component="span" className={classes.subtitle}>, a través de las plataformas de</Typography>
                    &nbsp;Youtube <Typography component="span" className={classes.subtitle}> y</Typography>
                    &nbsp;Zoom
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid item xs={12}>
                    <MenuBookIcon style={{ color: "#1de9b6", width: "3rem", height: "3rem", marginLeft: "-5px" }} />
                  </Grid>
                  <Typography className={classes.title}>
                    Semana del 18 al 24
                  </Typography>
                  <Typography component="span" className={classes.subtitleItem}>
                    <Typography className={classes.subtitle}>
                      Viernes 22 (20:00 hrs.)
                    </Typography>
                    Reunión de voluntarios
                    <Typography component="span" className={classes.subtitle}>, a través de la plataforma</Typography>
                    &nbsp;Zoom
                  </Typography>

                  <Typography component="span" className={classes.subtitleItem}>
                    <Typography className={classes.subtitle} style={{ marginTop: "3rem" }} >
                      Sábado 23 (19:30 hrs.)
                    </Typography>
                    Media vigilia de jóvenes

                    <Typography component="span" className={classes.subtitle}>, a través de las plataformas de</Typography>
                    &nbsp;Youtube <Typography component="span" className={classes.subtitle}> y</Typography>
                    &nbsp;Zoom
                  </Typography>

                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid item xs={12}>
                    <MenuBookIcon style={{ color: "#00b0ff", width: "3rem", height: "3rem", marginLeft: "-5px" }} />
                  </Grid>
                  <Typography className={classes.title}>
                    Semana del 25 al 31
                  </Typography>

                  <Typography component="span" className={classes.subtitleItem}>
                    <Typography className={classes.subtitle}>
                      Viernes 29 (20:00 hrs.)
                    </Typography>
                    Reunión del coro
                    <Typography component="span" className={classes.subtitle}>, a través de la plataforma</Typography>
                    &nbsp;Zoom
                  </Typography>

                  <Typography component="span" className={classes.subtitleItem}>
                    <Typography className={classes.subtitle} style={{ marginTop: "3rem" }}>
                      Sábado 30 (20:00 hrs.)
                    </Typography>
                    Reunión general
                    <Typography component="span" className={classes.subtitle}>, a través de las plataformas de</Typography>
                    &nbsp;Youtube <Typography component="span" className={classes.subtitle}> y</Typography>
                    &nbsp;Zoom
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Grid>
  );
};

export default CardsInformation;

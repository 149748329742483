import React from "react";
import Typography from "@material-ui/core/Typography";
//import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footerLink: {
    color: "#000",
    textDecoration: "none",
    fontSize: "14px",
    lineHeight: "24px",
    "&:hover": {
      color: "#026DD6",
      textDecoration: "none"
    }
  },
  footerText:{
    color: "#000",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: "14px"
  }
}));

const Copyright = props => {
  const classes = useStyles();

  return (
    <Typography className={classes.footerText} align="center">
      Copyright ©&nbsp;
      {new Date().getFullYear()}
      &nbsp;Iglesia Evangélica Pentecostal Melipilla. Todos los derechos reservados.
    </Typography>
  );
};

export default Copyright;

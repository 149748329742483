import React from "react";
import Grid from "@material-ui/core/Grid";
//import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import background from "./background.jpg";

//import FavoriteIcon from "@material-ui/icons/Favorite";
//import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
//import CreateIcon from "@material-ui/icons/Create";
//import AttachFileIcon from "@material-ui/icons/AttachFile";
//import PlayArrowIcon from "@material-ui/icons/PlayArrow";
//import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
//import AddIcon from "@material-ui/icons/Add";
//import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
//import CardContent from "@material-ui/core/CardContent";
//import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
//import CardActions from "@material-ui/core/CardActions";
//import Button from "@material-ui/core/Button";
//import CardMedia from "@material-ui/core/CardMedia";
//import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
//import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
//import Link from "@material-ui/core/Link";
//import TextField from "@material-ui/core/TextField";
//import { useTheme } from "@material-ui/core/styles";
//import useMediaQuery from "@material-ui/core/useMediaQuery";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import MailOutlineIcon from "@material-ui/icons/MailOutline";
//import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    justifyContent: "center"
  },
  card: {
    display: 'flex',
    flexDirection: 'column',

    marginTop: "0.5rem",
    lineHeight: "32px",
    boxShadow: "0 0 1px 0 rgba(8, 11, 14, 0.06), 0 16px 16px -1px rgba(8, 11, 14, 0.1)",
    borderRadius: "12px",
    //height: "24rem",
    width: "100%",
    border: "1px solid #F7F8F9",
    padding: "1.5rem",
    fontSize: "14px",

  },
  cardIcon:{

    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    paddingLeft: "9px",
    //marginTop: "0.5rem",
    lineHeight: "32px",
    boxShadow: "0 0 1px 0 rgba(8, 11, 14, 0.06), 0 3px 3px -1px rgba(8, 11, 14, 0.1)",
    borderRadius: "1000rem",
    //height: "24rem",
    //width: "19.5rem",
    border: "1px solid #F7F8F9",
    //padding: "1.5rem",
    fontSize: "14px",
    "&:hover": {
      boxShadow: "0 0 1px 0 rgba(8, 11, 14, 0.06), 0 16px 16px -1px rgba(8, 11, 14, 0.1)"
    }
  },
  cardImage: {
    //height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: "0.5rem",
    lineHeight: "32px",
    boxShadow: "0 0 1px 0 rgba(8, 11, 14, 0.06), 0 16px 16px -1px rgba(8, 11, 14, 0.1)",
    borderRadius: "12px",
    //height: "18rem",
    //height: "100%",
    width: "100%",
    border: "1px solid #F7F8F9",
    //padding: "1.5rem",
    fontSize: "14px",

    
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardMediaImage: {
    paddingTop: '85.25%', // 16:9
    height: 20
  },
  cardContent: {
    flexGrow: 1,
    justifyContent: "center"
  },
  linkCreate: {
    textTransform: "none",
    textDecoration: "none",
    color: "#0284FE",
    fontWeight: 500,
    lineHeight: "20px",
    fontSize: "12px",
    "&:hover": {
      color: "#026DD6",
      textDecoration: "none"
    }
  },
  buttonLogin: {
    backgroundColor: "#EEF7FF",
    height: "3rem",
    color: "#0284FE",
    borderRadius: "1000rem",
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: 500,
    border: "none",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    width: "300px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#DCEEFF",

    }

  },
  inputText: {
    borderRadius: "1000rem",
    border: "#E1E4E8",
    color: "#000000",
    lineHeight: "24px",
    fontSize: "14px",
    height: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0284FE",
      borderRadius: "1000rem",
      outline: "none"
    },
    "&:focus": {
      borderColor: "#0284FE",
      outline: "none"
    },
    "&::-webkit-input-placeholder": {
      color: "#E1E4E8"
      
    }
  },
  focusButton: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E1E4E8"
    },
    
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0284FE"
    },
  },
  avatarStyle: {
    width: "1.5rem",
    height: "1.5rem"
  },
  cardOnly: {
    height: "3rem"
  },
  avatarStyleBig: {
    width: "2rem",
    height: "2rem"
  },
  followButton: {
    textTransform: "none",
    borderRadius: "1000rem",
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: 500,
    color: "#fff",
    backgroundColor: "#0284FE",
    height: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "&:hover": {
      backgroundColor: "#026DD6"
    }

  },
  messageButton: {
    textTransform: "none",
    border: "1px solid #C9CED6",
    borderRadius: "1000rem",
    color: "#505256",
    backgroundColor: "#fff",
    lineHeight: "24px",
    fontSize: "14px",
    fontWeight: 500,
    height: "2.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "&:hover": {
      backgroundColor: "#FAFBFB"
    }
  },
  avatarBig: {
    height: "5rem",
    width: "5rem"
  },



  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: "100%",
    borderRadius: "2rem"
  },
  overlay: {
    borderRadius: "2rem",
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    borderRadius: "2rem",
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

const Cards = props => {
  const classes = useStyles();
  //const theme = useTheme();
  //const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid item container xs={12} style={{ marginTop: "3rem" }}>
      <Grid item container xs={12}>
        <Grid item sm={1} xl={2} />
        <Grid item container xs={12} sm={10} xl={8} justify="center">
          <Grid item container xs={10} >
            <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${background})` }}>
              {/* Increase the priority of the hero background image */}
              {<img style={{ display: 'none' }} src={background} alt="d" />}
              <div className={classes.overlay} />
              <Grid container justify="center">
                <Grid item container xs={12} justify="center">
                  <div className={classes.mainFeaturedPostContent}>

          <Grid item container xs={12} justify="center" style={{ marginTop: "2rem" }}>
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              component="p"
              style={{ color: "#fff", fontStyle: "italic", fontWeight: "bold", fontSize: "30px" }}
            >
              &quot;Alabaré a Jehová con todo el corazón
            </Typography>
          </Grid>

          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
            style={{ color: "#fff", fontStyle: "italic", fontWeight: "bold", fontSize: "30px" }}
          >
            En la compañía y congregación de los rectos&quot;.
          </Typography>

          <Grid item container xs={12} justify="flex-end">
            <Typography
              variant="subtitle1"
              align="right"
              color="textSecondary"
              style={{ color: "#fff", fontWeight: "bold", fontSize: "30px" }}
            >
              Salmos 111: 1
            </Typography>
          </Grid>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          
          <Grid item container xs={12}>
            <Grid item xs={1} />
            <Grid item container xs={10} justify="center">
              {/* First Card */}
              {/*}
              <Grid item container xs={12} sm={6} md={4} style={{ marginTop: "2rem" }}>
                <Grid item container xs={12} justify="space-around">
                  <Grid item container xs={2} justify="center">
                    <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                      <FavoriteIcon style={{ color: "rgb(244, 84, 29)", width: "1.2rem", height: "1.2rem"}}/>
                    </Card>
                  </Grid>
                  <Grid item container xs={2} justify="center">
                    <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                      <VisibilityOutlinedIcon style={{ color: "rgb(2, 132, 254)", width: "1.2rem", height: "1.2rem" }} />
                    </Card>
                  </Grid>
                  <Grid item container xs={2} justify="center">
                    <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                      <CreateIcon style={{ color: "rgb(247, 175, 34)", width: "1.2rem", height: "1.2rem" }} />
                    </Card>
                  </Grid>
                  <Grid item container xs={2} justify="center">
                    <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                      <AttachFileIcon style={{ color: "rgb(54, 171, 128)", width: "1.2rem", height: "1.2rem" }} />
                    </Card>
                  </Grid>
                  <Grid item container xs={2} justify="center">
                    <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                      <PlayArrowIcon style={{ color: "rgb(244, 84, 29)", width: "1.2rem", height: "1.2rem" }} />
                    </Card>
                  </Grid>
                  <Grid item container xs={2} justify="center">
                    <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                      <GetAppOutlinedIcon style={{ color: "rgb(2, 132, 254)", width: "1.2rem", height: "1.2rem" }} />
                    </Card>
                  </Grid>
                </Grid>
                <Grid item container xs={12} justify="center" style={{ marginTop: "1.5rem" }}>
                  <Card className={classes.card} >
                    <CardContent className={classes.cardContent}>
                      <Grid item container xs={12} justify="center">
                        <Avatar className={classes.avatarBig} alt="Remy Sharp" src="https://atomizecode.com/static/girl2-347308679d08c4bc3b4fb0311ebee728.png" />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{ marginTop: "1.2rem" }}>
                        <Typography
                          style={{
                            lineHeight: "32px",
                            fontSize: "22px",
                            fontWeight: 500
                          }}
                        >
                          Meagan Fisher
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} justify="center">
                        <Typography
                          style={{
                            lineHeight: "20px",
                            fontSize: "12px",
                            color: "#A7AAB0"

                          }}
                        >
                          Engineer Manager
                        </Typography>
                      </Grid>
                    </CardContent>
                    {matches ? (
                      <CardActions style={{ justifyContent: "center" }}>
                        <Button
                          className={classes.followButton}
                        >
                          Follow
                        </Button>
                        <Button
                          className={classes.messageButton}
                        >
                          Message
                        </Button>
                      </CardActions>
                    ) : (
                      <CardActions style={{ justifyContent: "center" }}>
                        <Button
                          className={classes.followButton}
                          endIcon={<AddIcon>Follow</AddIcon>}
                        >
                          Follow
                        </Button>
                        <Button
                          className={classes.messageButton}
                          endIcon={< ChatBubbleOutlineIcon>Message</ChatBubbleOutlineIcon>}
                        >
                          Message
                        </Button>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              </Grid>
              */}
              {/* End First Card */}
              {/* Second Card */}
              {/*}
                <Grid item container xs={12} sm={6} md={4} justify="center" style={{ marginTop: "2rem" }}>
                  <Grid item container xs={12}>
                    <Grid item xs={1} />
                    <Grid item container xs={10}>
                      <Grid item container xs={12}>
                        <Card className={classes.cardImage} >
                          <CardMedia
                            className={classes.cardMediaImage}
                            image="https://source.unsplash.com/random"
                            title="Image title"
                          />
                          <CardActions>
                            <Grid container alignItems="center" style={{ marginTop: "8px" }}>
                              <Grid item container xs={0} justify="center">
                                <Avatar className={classes.avatarStyle} alt="Remy Sharp" src="https://atomizecode.com/static/girl2-347308679d08c4bc3b4fb0311ebee728.png" />
                              </Grid>
                              <Grid item container xs={12} justify="center">
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    marginLeft: "5px"
                                  }}
                                  align="center"
                                >
                                  &nbsp;
                                </Typography>
                              </Grid>
                              <Grid item container xs={0}>
                                {/*}
                                <FavoriteBorderIcon />
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item container xs={12} alignItems="center" style={{ marginTop: "1rem" }}>
                        <Card className={classes.cardImage} >
                          <CardActions>
                            <Grid container alignItems="center">
                              <Grid item container xs={2} justify="center">
                                <Avatar className={classes.avatarStyleBig} alt="Remy Sharp" src="https://atomizecode.com/static/girl2-347308679d08c4bc3b4fb0311ebee728.png" />
                              </Grid>
                              <Grid item container xs={8} alignItems="center" >
                                <Grid item xs={12}>

                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    marginLeft: "10px"
                                  }}
                                >
                                  John Doe
                                </Typography>
                                </Grid>
                                <Grid item xs={12}>

                                <Typography
                                  style={{
                                    color: "#A7AAB0",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "20px",
                                    marginLeft: "10px"
                                  }}
                                >
                                  Durante este tiempo de distanciamiento social, hemos establecido la Iglesia de Melipilla desde casa para que podamos brindarle aliento, comunidad y apoyo directamente en su hogar.
                                </Typography>
                                </Grid>
                              </Grid>
                              <Grid item container xs={2}>
                                <CreateOutlinedIcon />
                              </Grid>
                            </Grid>
                          </CardActions>

                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </Grid>
                                */}
                {/* Third Card */}
                {/*}
                <Grid item container xs={12} sm={6} md={4} justify="center" style={{ marginTop: "2rem" }}>
                  <Grid item container xs={12} justify="center">
                    <Card className={classes.card} style={{ justifyContenty: "center" }}>
                      <CardContent className={classes.cardContent}>
                        <Typography
                          style={{
                            marginTop: "0.5rem",
                            lineHeight: "32px",
                            marginBottom: "0.5rem",
                            fontSize: "21px",
                            fontWeight: 500,
                            justifyContent: "center",
                            textAlign: "center"
                          }}
                        >
                          Login into your account
                        </Typography>
                        <Typography
                          style={{
                            marginBottom: "4rem",
                            lineHeight: "20px",
                            fontSize: "12px",
                            color: "#A7AAB0",
                            justifyContent: "center",
                            textAlign: "center"
                          }}
                          align="center"
                        >
                          Don't have an account yet?&nbsp;
                          <Link variant="button" color="textPrimary" href="#" 
                            className={classes.linkCreate}
                          >
                            Create New
                          </Link> 
                        </Typography>
                        <Grid item container xs={12} justify="center">
                          <TextField
                            fullWidth
                            //style={{ marginTop: "4em", outline: "none" }}
                            className={classes.focusButton}
                            placeholder="johndoe@gmail.com"
                            id="mail-login"
                            variant="outlined"
                            InputProps={{
                              className: classes.inputText,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MailOutlineIcon style={{ color: "#E1E4E8"}}  />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            fullWidth
                            placeholder="Password"
                            className={classes.focusButton}
                            style={{ marginTop: "1em" }}
                            type="password"
                            id="password-login"
                            variant="outlined"
                            InputProps={{
                              className: classes.inputText,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <LockOutlinedIcon style={{ color: "#E1E4E8" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                  
                  
                  
                      </CardContent>
                      <CardActions style={{ justifyContent: "center" }}>
                        <Button
                          className={classes.buttonLogin}
                        >
                          Login
                        </Button>
                      </CardActions>
                    </Card>

                  </Grid>
                </Grid>
                          */}
                {/* End Thrid Card */}
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item sm={1} xl={2} />
      </Grid>


{/*


      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid item container xs={12} spacing={2} style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}>
              <Grid item xs={2}>
                <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                  <FavoriteIcon style={{ color: "rgb(244, 84, 29)", width: "1.2rem", height: "1.2rem"}}/>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                  <VisibilityOutlinedIcon style={{ color: "rgb(2, 132, 254)", width: "1.2rem", height: "1.2rem" }} />
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                  <CreateIcon style={{ color: "rgb(247, 175, 34)", width: "1.2rem", height: "1.2rem" }} />
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                  <AttachFileIcon style={{ color: "rgb(54, 171, 128)", width: "1.2rem", height: "1.2rem" }} />
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                  <PlayArrowIcon style={{ color: "rgb(244, 84, 29)", width: "1.2rem", height: "1.2rem" }} />
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card className={classes.cardIcon} style={{ width: "2.5rem", height: "2.5rem" }}>
                  <GetAppOutlinedIcon style={{ color: "rgb(2, 132, 254)", width: "1.2rem", height: "1.2rem" }} />
                </Card>
              </Grid>
            </Grid>
            <Card className={classes.card} style={{ height: "19.5rem", width: "19.5rem", maxWidth: "19.5rem" }}>
              <CardContent className={classes.cardContent}>
                <Grid item container xs={12} justify="center">
                  <Avatar className={classes.avatarBig} alt="Remy Sharp" src="https://atomizecode.com/static/girl2-347308679d08c4bc3b4fb0311ebee728.png" />
                </Grid>
                <Grid item container xs={12} justify="center" style={{ marginTop: "1.2rem" }}>
                  <Typography
                    style={{
                      lineHeight: "32px",
                      fontSize: "22px",
                      fontWeight: 500
                    }}
                  >
                    Meagan Fisher
                  </Typography>
                </Grid>
                <Grid item container xs={12} justify="center">
                  <Typography
                    style={{
                      lineHeight: "20px",
                      fontSize: "12px",
                      color: "#A7AAB0"

                    }}
                  >
                    Engineer Manager
                  </Typography>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  className={classes.followButton}
                  endIcon={<AddIcon>Follow</AddIcon>}
                >
                  Follow
                </Button>
                <Button
                  className={classes.messageButton}
                  endIcon={< ChatBubbleOutlineIcon>Message</ChatBubbleOutlineIcon>}
                >
                  Message
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}  
            //style={{ paddingLeft: "2.3rem" }}
          >

            <Card className={classes.cardImage} style={{ width: "17rem" }}>
              <CardMedia
                className={classes.cardMediaImage}
                image="https://source.unsplash.com/random"
                title="Image title"
              />
              
                

              <CardActions>
                <Grid container alignItems="flex-start" style={{ marginTop: "8px" }}>
                  <Grid item container xs={2} justify="center">
                    <Avatar className={classes.avatarStyle} alt="Remy Sharp" src="https://atomizecode.com/static/girl2-347308679d08c4bc3b4fb0311ebee728.png" />
                  </Grid>
                  <Grid item container xs={8}>
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        marginLeft: "5px"
                      }}
                    >Meagan Fisher</Typography>
                      
                  </Grid>
                  <Grid item container xs={2}>
                    <FavoriteBorderIcon />
                  </Grid>
                </Grid>
              </CardActions>

            </Card>

            <Card className={classes.cardImage} style={{ width: "17rem", height: "4rem", marginTop: "2rem" }}>
              
                

              <CardActions>
                <Grid container alignItems="flex-start" style={{ marginTop: "8px" }}>
                  <Grid item container xs={2} justify="center">
                    <Avatar className={classes.avatarStyleBig} alt="Remy Sharp" src="https://atomizecode.com/static/girl2-347308679d08c4bc3b4fb0311ebee728.png" />
                  </Grid>
                  <Grid item container xs={8} alignItems="flex-start" style={{ marginTop: "-6px" }}>
                    <Grid item xs={12}>

                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        marginLeft: "10px"
                      }}
                    >John Doe</Typography>
                    </Grid>
                    <Grid item xs={12}>

                    <Typography
                      style={{
                        color: "#A7AAB0",
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        marginLeft: "10px"
                      }}
                    >UI/UI Designer</Typography>
                    </Grid>
                      
                  </Grid>
                  <Grid item container xs={2}>
                    <CreateOutlinedIcon />
                  </Grid>
                </Grid>
              </CardActions>

            </Card>



          </Grid>

          <Grid item xs={12} sm={6} md={4} >
            <Card className={classes.card} style={{ width: "19.5rem", maxWidth: "19.5rem" }}>
              <CardContent className={classes.cardContent}>
                <Typography
                  style={{
                    marginTop: "0.5rem",
                    lineHeight: "32px",
                    marginBottom: "0.5rem",
                    fontSize: "21px",
                    fontWeight: 500,
                  }}
                >
                  Login into your account
                </Typography>
                <Typography
                  style={{
                    marginBottom: "4rem",
                    lineHeight: "20px",
                    fontSize: "12px",
                    color: "#A7AAB0"
                  }}
                  component={"span"}
                >
                  Don't have an account yet?&nbsp;
                  <Link variant="button" color="textPrimary" href="#" 
                    className={classes.linkCreate}
                  >
                    Create New
                  </Link> 
                </Typography>
                  
                <TextField
                  style={{ marginTop: "4em", outline: "none" }}
                  className={classes.focusButton}
                  placeholder="johndoe@gmail.com"
                  id="mail-login"
                  variant="outlined"
                  InputProps={{
                    className: classes.inputText,
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon style={{ color: "#E1E4E8"}}  />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  placeholder="Password"
                  className={classes.focusButton}
                  style={{ marginTop: "1em" }}
                  type="password"
                  id="password-login"
                  variant="outlined"
                  InputProps={{
                    className: classes.inputText,
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockOutlinedIcon style={{ color: "#E1E4E8" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                  
                  
              </CardContent>
              <CardActions>
                <Button
                  className={classes.buttonLogin}
                >
                  Login
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
                */}
    </Grid>
  );
};

export default Cards;

import React from "react";
import Copyright from "./Copyright";
//import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  footerTitle: {
    fontSize: "16px",
    lineHeight: "80px",
    fontWeight: "bold",
    color: "#000"
  },
  footerLink: {
    color: "#505256",
    textDecoration: "none",
    fontSize: "14px",
    lineHeight: "24px",
    "&:hover": {
      color: "#026DD6",
      textDecoration: "none"
    }
  },
  itemList: {
    marginLeft: "-1rem",
    marginTop: "0.1rem"
  }
}));


const Footer = props => {
  const classes = useStyles();

  return (

    <Container maxWidth="md" component="footer" className={classes.footer}>
      <Grid container spacing={4} justify="space-evenly">
        <Grid item container xs={12}>
          <Grid item xs={1} xl={2} />
          <Grid item container xs={10} xl={8} justify="center">
            <Grid item container xs={12} spacing={6} justify="space-around">

                {/*
              <Grid item xs={6} sm={3}>
                <Typography className={classes.footerTitle}>
                  I.E.P Melipilla
                </Typography>
                <List>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Installation
                    </Link>
                  </ListItem>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Theme Setup
                    </Link>
                  </ListItem>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Grid
                    </Link>
                  </ListItem>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Atoms
                    </Link>
                  </ListItem>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Molecules
                    </Link>
                  </ListItem>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Functions
                    </Link>
                  </ListItem>
                </List>
              </Grid>

  */}
              <Grid item container xs={8} sm={4} justify="center">
                <Typography className={classes.footerTitle} style={{ color: "#0284FE" }}>
                  ACOMPÁÑENOS
                </Typography>
                <Typography className={classes.footerLink} align="center">
                  Cada sábado a las 20:00 hrs., de manera online
                </Typography>
                <Typography onClick={() => {

                    window.open("https://www.youtube.com/channel/UCZPquZRVZTPhXmPbxKyflTA", "_blank");
                }}>
                  <YouTubeIcon style={{ width: "2.5rem", height: "2.5rem", color: "#e53935"  }} />
                </Typography>
                {/*
                <List>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Features
                    </Link>
                  </ListItem>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Design
                    </Link>
                  </ListItem>
                  <ListItem className={classes.itemList}>
                    <Link href="#" className={classes.footerLink}>
                      Development
                    </Link>
                  </ListItem>
                </List>
*/}
              </Grid>

              <Grid item container xs={8} sm={4} justify="center">
                <Typography className={classes.footerTitle} style={{ color: "#0284FE" }}>
                  DIRECCIÓN
                </Typography>
                <Typography className={classes.footerLink} align="center">
                  Arza #856, Melipilla, Región Metropolitana, Chile.
                </Typography>
                <Typography className={classes.footerLink} style={{ marginTop: "1rem", fontStyle: "italic" }} align="center">
                  Templo temporalmente cerrado debido a la pandemia.
                </Typography>
              </Grid>

              <Grid item container xs={8} sm={4} justify="center">
                <Typography className={classes.footerTitle} style={{ color: "#0284FE" }}>
                  CONTACTO
                </Typography>
                <Typography component="span" className={classes.footerLink} align="center">
                  Cel: +56964609478 
                </Typography>

                <Typography className={classes.footerLink} align="center">
                  Correo:
                  comunicaciones@iepmelipilla.cl
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} xl={2} />
        </Grid>
        
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Footer;

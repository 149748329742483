import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    lineHeight: "48px",
    fontSize: "32px"
  },
  subtitle: {
    marginTop: "1rem",
    lineHeight: "30px",
    fontSize: "17px",
    color: "#505256"
  }
}));

const Text = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  
  return (
    <Grid container style={{ marginTop: "2rem" }}>
      <Grid item container xs={12}>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          {matches ? (
            <Typography className={classes.title} align="center">
              Actividades del mes de enero
            </Typography>
          ) : (
            <Typography className={classes.title}>
              Actividades del mes de enero
            </Typography>
          )}
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
      {/*}
      {matches ? (
        <Grid item container xs={12}>
          <Grid item xs={1} xl={2} />
            <Grid item xs={10} xl={8}>
              <Typography className={classes.subtitle} align="center">
                A continuación, las actividades de la iglesia para el presente mes.
              </Typography>
            </Grid>
          <Grid item xs={1} xl={2} />
        </Grid>
      ) : (
        <Grid item container xs={12}>
          <Grid item xs={1} xl={2} />
            <Grid item xs={4}>
              <Typography className={classes.subtitle}>
                A continuación, las actividades de la iglesia para el presente mes.
              </Typography>
            </Grid>
            <Grid item xs={6} />
          <Grid item xs={1} xl={2} />
        </Grid>
      )}
      */}
    </Grid>
  );
};

export default Text;

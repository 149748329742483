import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  chipTag:{
    backgroundColor: "#000",
    color: "#fff",
    fontSize: "20px"
  }
}));

const Tag = () => {
  const classes = useStyles();

  return (
   <Grid item container xs={12} justify="flex-start" style={{ marginTop: "7rem" }}>
     <Grid item xs={1} xl={2} />
     <Grid item xs={10} xl={8}>
        <Chip label="Calendario año 2021" className={classes.chipTag} />
     </Grid>
     <Grid item xs={1} xl={2} />
   </Grid> 
  );
};

export default Tag;

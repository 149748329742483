import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import WebBar from "../../Components/Website/WebBar/WebBar";
import WelcomeMessage from "../../Components/Website/WelcomeMessage/WelcomeMessage";
import Cards from "../../Components/Website/Cards/Cards";
import Footer from "../../Components/Website/Footer/Footer";
import Divider from "../../Components/Website/Divider/Divider";
import InformationSegment from "../../Components/Website/InformationSegment/InformationSegment";
//import SecondaryInformationSegment from "../../Components/Website/SecondaryInformationSegment/SecondaryInformationSegment";
//import SmallInformation from "../../Components/Website/SmallInformation/SmallInformation";
//import BottomMessage from "../../Components/Website/BottomMessage/BottomMessage";
//import Copyright from "../../Components/Website/Footer/Copyright";
//import { Typography } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff"
  },
  buttonWhite: {
    marginTop: "2rem",
    marginRight: "1rem",
    marginLeft: "1rem",
    backgroundColor: "#0284FE",
    //border: "1px solid #E1E4E8",
    boxShadow: "none",
    color: "#fff",
    borderRadius: "8px",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    lineHeight: "24px",
    fontSize: "14px",
    height: "3rem",
    textTransform: "none",
    width: "12rem",
    "&:hover": {
      boxShadow: "none",
      //border: "1px solid #B0B7C3",
      backgroundColor: "#4BA7FE"
    }
  }
}));

const Home = props => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {/*}
      <WebBar />
      <WelcomeMessage />
  */}
      {/*}
      <Divider top={"5rem"} />
  */}
  {/*}
      <Cards />
*/}
      {/*}
      <Grid item container xs={12} justify="center">
        <Grid item container xs={12} justify="center">
          <Grid item container xs={8} justify="center" style={{ marginTop: "1em" }}>
            <Typography
              component="h1" 
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
              style={{ fontWeight: 500, fontSize: "22px", color: "#000", lineHeight: "56px" }}
            >
              CONECTADOS DESDE CASA
            </Typography>
          </Grid>

          <Grid item container xs={10} md={8} xl={8} justify="center">
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              component="p"
              style={{ color: "#505256" }}
            >
              Amados hermanos y hermanas, durante este tiempo de distanciamiento social hemos tenido que cambiar nuestra forma de congregarnos. Esto a significado utilizar las plataformas online que están a nuestra disposición, ya que debemos comprender que durante algún tiempo, mientras dure la actual situación esta será nuestra forma de seguir escuchando la palabra de Dios y mantener un contacto con toda la hermandad. Esperamos en la voluntad de Dios para poder nuevamente congregarnos de forma presencial.
            </Typography>

            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              component="p"
              style={{ color: "#505256" }}
            >
              Que nuestro Dios nos bendiga
            </Typography>

          </Grid>
          <Grid item container xs={10} justify="center">

            <Button
              variant="contained"
              className={classes.buttonWhite}
              onClick={() => {
                window.open("https://www.youtube.com/channel/UCZPquZRVZTPhXmPbxKyflTA", "_blank")
              }}
            >
              <PlayArrowIcon />&nbsp;
              Canal de YouTube
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Typography>
          </Typography>
        </Grid>
      </Grid>
            */}
            {/*}
            <Grid item container xs={12} justify="center">

            <Button
              variant="contained"
              className={classes.buttonWhite}
              onClick={() => {
                window.open("https://www.youtube.com/channel/UCZPquZRVZTPhXmPbxKyflTA", "_blank")
              }}
            >
              <PlayArrowIcon />&nbsp;
              Canal de YouTube
            </Button>
          </Grid>
      <Divider top={"8rem"} />
      <InformationSegment />
      <Divider top={"8rem"} />
            */}
      {/*}
      <SecondaryInformationSegment />
      <Divider top={"8rem"} />
            */}
      {/*
      <SmallInformation />

      */}
      {/*}
      <BottomMessage />
    */}
    {/*}
      <Footer />
      <CssBaseline />
  */}
    </Grid>
  );
}

export default Home;

// Durante este tiempo de distanciamiento social, hemos establecido la Iglesia de Melipilla desde casa para poder brindarle aliento, comunidad y apoyo directamente en su hogar.